const formInputs = [
  { id: 0, name: 'firstName', label: 'First name' },
  { id: 1, name: 'lastName', label: 'Last name' },
  { id: 2, name: 'email', label: 'Email' },
  { id: 3, name: 'address', label: 'Address line' },
  { id: 4, name: 'city', label: 'City' },
  { id: 5, name: 'country', label: 'Country' },
];

export default formInputs;
